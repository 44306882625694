<template>
  <b-card-code>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="submitForm">
        <b-row>
          <b-col md="4">
            <b-form-group>
              <label>Land Use For</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="For Sale"
              >
                <v-select
                  v-model="landusefor"
                  :options="['Project', 'Sales']"
                  placeholder="None"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>In SIR?</label>
              <v-select
                placeholder="None"
                v-model="sir"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="['Yes', 'No']"
                @input="handleSir()"
              />
            </b-form-group>
          </b-col>
          <b-col md="4" v-if="sir == 'Yes'">
            <b-form-group>
              <label>Selelct Zone</label>
              <v-select
                placeholder="None"
                v-model="zone"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="zoneOptions"
              />
            </b-form-group>
          </b-col>

          <b-col md="4" v-if="sir == 'Yes'">
            <b-form-group>
              <label>Selelct T.P.</label>
              <v-select
                placeholder="None"
                v-model="tpno"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                @input="handleSubTP"
                :options="[1, 2, 3, 4, 5, 6]"
              />
            </b-form-group>
          </b-col>
          <b-col md="4" v-if="sir == 'Yes'">
            <b-form-group>
              <label>Selelct Sub T.P.</label>
              <v-select
                placeholder="None"
                v-model="subtpno"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                @input="handleVillage"
                :options="subtpOptions"
              />
            </b-form-group>
          </b-col>
          <!-- <b-col md="4" v-if="sir == 'Yes'">
            <b-form-group>
              <label>Village Name</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Village Name"
              >
                <v-select
                  placeholder="None"
                  v-model="villagename"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="villageOption"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->
          <b-col md="4">
            <b-form-group>
              <label>Village Name</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Village Name"
              >
                <b-form-input
                  v-model="villagename"
                  placeholder="Enter Village Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Survey No. Old</label>
              <b-form-input
                v-model="surveynoold"
                type="text"
                placeholder="Enter Survey No. Old"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Survey No. New</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Survey No. New"
              >
                <b-form-input
                  v-model="surveynonew"
                  type="text"
                  placeholder="Enter Survey No. New"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>Khata No.</label>
              <b-form-input
                v-model="khatano"
                type="text"
                placeholder="Enter Khata No."
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Original Plot No. (OP)</label>
              <b-form-input
                v-model="orignalplotno"
                type="text"
                placeholder="Enter Original Plot No. (OP)"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Taluka/Tehsil</label>
              <b-form-input
                v-model="taluka"
                placeholder="Enter Taluka/Tehsil"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Patwari Hulka No.</label>
              <b-form-input
                v-model="patwarihulkano"
                type="text"
                placeholder="Enter Patwari Hulka No."
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>State</label>
              <v-select
                v-model="state"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                placeholder="None"
                :options="stateOptions"
                label="State"
                @input="handleCity()"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>City</label>
              <v-select
                v-model="dist"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="cityOption"
                placeholder="None"
                label="City"
              />
            </b-form-group>
          </b-col>
          <!-- <b-col md="12"></b-col> -->

          <b-col md="4">
            <b-form-group>
              <label>Total Area</label>
              <small>(In Sq.Yard)</small>

              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Total Area"
              >
                <b-form-input
                  v-model="areainyd"
                  @input="handleTotalAreaYard()"
                  placeholder=" Enter Total Area"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Total Area</label>
              <small>(In Sq.MTR)</small>

              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Total Area"
              >
                <b-form-input
                  @input="handleTotalAreaMTR()"
                  v-model="areainmtr"
                  placeholder=" Enter Total Area"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>Sq. Yard in 1 Bigha</label>

              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Sq. yard"
              >
                <b-form-input
                  v-model="yardinbigha"
                  placeholder=" Enter  Area"
                  @input="handleTotalAreaYard()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>Total Area</label>
              <small>(In Bigha)</small>

              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Total Area"
              >
                <b-form-input
                  v-model="totalarea"
                  placeholder=" Enter Total Area"
                  @input="handleTotalArea('bigha')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>Front Road Size</label>
              <small>(In Sq.MTR)</small>
              <b-form-input
                v-model="frontrodesize"
                placeholder=" Enter Road Size"
              />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>Corner Plot</label>
              <v-select
                v-model="cornerplot"
                placeholder="None"
                :options="['Yes', 'No']"
              />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>Side Road Size</label>
              <small>(In Sq.MTR)</small>

              <b-form-input
                v-model="siderodesize1"
                placeholder=" Enter Road Size"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Side Road Size</label>
              <small>(In Sq.MTR)</small>

              <b-form-input
                v-model="siderodesize2"
                placeholder=" Enter Road Size"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Other Side Road Size</label>
              <small>(In Sq.MTR)</small>

              <b-form-input
                v-model="othersideroadsize"
                placeholder=" Enter Road Size"
              />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>Is FP?</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Fp"
              >
                <v-select
                  v-model="isfp"
                  placeholder="None"
                  :options="['Yes', 'No']"
                  @input="handleTotal"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4" :style="isfp == 'Yes' ? '' : 'display:none'">
            <b-form-group>
              <label>FP No.</label>
              <b-form-input
                v-model="fpno"
                type="number"
                placeholder="Enter FP No."
              />
            </b-form-group>
          </b-col>

          <b-col md="4" :style="isfp == 'Yes' ? '' : 'display:none'">
            <b-form-group>
              <label>FP Area</label>
              <small>(In Sq.Yard)</small>
              <b-form-input
                v-model="fparea"
                placeholder=" Enter FP Area"
                @input="handleTotal"
              />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>With NA?</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="With NA"
              >
                <v-select
                  v-model="withna"
                  :options="['Yes', 'No']"
                  placeholder="None"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4" :style="withna == 'No' ? '' : 'display:none'">
            <b-form-group>
              <label>NA Charges</label>
              <small>(Per Bigha)</small>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                :rules="withna == 'No' ? 'required' : ''"
                name="NA Charges"
              >
                <b-form-input
                  v-model="nacharges"
                  placeholder="Enter NA Charges"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>Owner's Rate</label>
              <small>(Per Sq. Yard)</small>

              <b-form-input
                v-model="ownerrate"
                placeholder="Enter Owner's Rate"
                @input="handleTotal"
              />
            </b-form-group>
          </b-col>

          <b-col md="4" v-if="landusefor == 'Sales'">
            <b-form-group>
              <label>CP Final Rate</label>
              <small>(Per Sq.Yard)</small>

              <b-form-input
                v-model="cprate"
                placeholder="Enter CP Final Rate"
                @input="handleTotal"
              />
            </b-form-group>
          </b-col>
          <b-col md="4" v-if="landusefor == 'Sales'">
            <b-form-group>
              <label>Market Rate</label>
              <small>(Per Sq.Yard)</small>

              <b-form-input
                v-model="marketrate"
                placeholder="Enter Market Rate"
                @input="handleTotal"
              />
            </b-form-group>
          </b-col>
          <b-col md="12"></b-col>

          <b-col md="4">
            <b-form-group>
              <label>Total Owner's Amount</label>

              <b-form-input
                v-model="totalowneramount"
                placeholder="Enter Owner's Amount"
                disabled
              />
            </b-form-group>
          </b-col>

          <b-col md="4" v-if="landusefor == 'Sales'">
            <b-form-group>
              <label>Total CP Final Amount</label>

              <b-form-input
                v-model="totalcpamount"
                placeholder="Enter CP Final Amount"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col md="4" v-if="landusefor == 'Sales'">
            <b-form-group>
              <label>Total Market Amount</label>

              <b-form-input
                v-model="totalmarketamount"
                placeholder="Enter Market Amount"
                disabled
              />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>Land Payment Time</label>
              <b-form-input v-model="paymenttime" placeholder="Enter Time" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Token Amount</label>
              <b-form-input v-model="tokenamount" placeholder="Enter Amount" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Jantri Rate</label>
              <b-form-input v-model="Juntrirate" placeholder="Enter Jantri Rate" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Min. Booking Amount</label>
              <b-form-input v-model="bookingamount" placeholder="Enter Booking Amount" />
            </b-form-group>
          </b-col>

          <b-col md="4" v-if="landusefor == 'Sales'">
            <label>Assign To</label>
            <b-form-group style="margin-top: 7px">
              <b-form-checkbox v-model="cp" @input="handleCpFr($event)" inline>
                Channel Partner
              </b-form-checkbox>
              <b-form-checkbox v-model="fr" @input="handleCpFr($event)" inline>
                Franchise
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col md="4" v-if="landusefor == 'Sales'">
            <b-form-group>
              <label>Select CP/Franchise</label>
              <v-select
                v-model="assignto"
                placeholder="None"
                :options="assignToOption"
                @input="handleAll($event)"
                multiple
                label="fullname"
              />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>Land Proposal Attach Pdf file</label>
              <b-form-file
                v-model="landproposal"
                placeholder="Select Document"
                drop-placeholder="Drop file here..."
                @input="handleChangeFile($event, 'landmaster', 'landproposal')"
                accept="image/*"
              />
            </b-form-group>
            <attachment :data="landproposal" />
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>Map Image</label>
              <b-form-file
                v-model="mapimage"
                placeholder="Select Document"
                drop-placeholder="Drop file here..."
                @input="handleChangeFile($event, 'landmaster','mapimage')"
                accept="image/*"
              />
            </b-form-group>
            <attachment :data="mapimage" />
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>Guidline</label>
              <b-form-file
                v-model="guidline"
                placeholder="Select Document"
                drop-placeholder="Drop file here..."
                @input="handleChangeFile($event, 'landmaster','guidline')"
                accept="image/*"
              />
            </b-form-group>
            <attachment :data="guidline" />
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>KMZ File</label>
              <b-form-file
                v-model="kmzfile"
                placeholder="Select Document"
                drop-placeholder="Drop file here..."
                @input="handleChangeFile($event, 'landmaster','kmzfile')"
                accept="image/*"
              />
            </b-form-group>
            <attachment :data="kmzfile" />
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>Land Video</label>
              <b-form-input v-model="landvideo" placeholder="Enter Video" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Land Location</label>
              <b-form-input v-model="landloacation" placeholder="Enter Location" />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>Other Details</label>
              <b-form-textarea
                v-model="details"
                rows="3"
                placeholder="Enter Details"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Remarks</label>
              <b-form-textarea
                v-model="remarks"
                rows="3"
                placeholder="Enter Remarks"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-card-code title="Highlights" no-body />
          </b-col>

          <b-col md="12">
            <div style="width: 100%; overflow: auto; max-height: 200px;">
              <table class="table table-bordered">
                <thead class="text">
                  <tr>
                    <th>Sr. No</th>
                    <th>Name</th>
                    <th>Action</th>

                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, id) in highlights" :key="id">
                    <td>{{ id + 1 }}</td>
                    <td>
                      <b-form-input v-model="item.name" placeholder="Enter Deatils" />
                    </td>
                    <td>
                      <b-button variant="outline-danger" @click="removeHighlightsRow(id)">
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-button variant="primary" @click="addHighlightsRow" class="my-1">
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>Add More</span>
            </b-button>
          </b-col>
          <!-- <b-col md="12">
            <b-form-group>
              <label>Description</label>
              <b-form-textarea
                v-model="description"
                rows="3"
                placeholder="Enter Remarks"
              />
            </b-form-group>
          </b-col> -->
          <b-col cols="12" class="mt-1">
            <b-button
              variant="primary"
              type="submit"
              @click.prevent="submitForm($event)"
              class="mr-4"
              :disabled="flag"
            >
              Submit
            </b-button>
            <b-button variant="primary" @click="onClickBack">Back </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <!-- <template #code>
      {{ codeType }}
    </template> -->
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import flatPickr from "vue-flatpickr-component";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import PinchScrollZoom from "@coddicat/vue-pinch-scroll-zoom";
import Attachment from "../../../../components/Attechment.vue";

import {
  BFormInput,
  BFormFile,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  BFormCheckbox,
} from "bootstrap-vue";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
  min_value,
} from "@validations";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import axios from '@/components/axios';

export default {
  components: {
    Attachment,
    flatPickr,
    BFormCheckbox,
    PinchScrollZoom,
    Datepicker,
    BFormFile,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormDatepicker,
    BFormTextarea,
    BImgLazy,
  },
  data() {
    return {
      sir: "",
      description:'',
      isfp: "",
      fparea: "",
      totalarea: "",
      withna: "",
      nacharges: "",

      cprate: "",
      ownerrate: "",
      marketrate: "",

      tpno: "",
      subtpno: "",

      ownerrate: "",
      cprate: "",
      marketrate: "",
      zone: "",

      villagename: "",
      surveynoold: "",
      surveynonew: "",
      fpno: "",
      khatano: "",
      orignalplotno: "",
      taluka: "",
      patwarihulkano: "",
      dist: "",
      state: "",
      remarks: "",
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      ifEdit: false,
      flag: false,
      cityOption: [],
      stateOptions: [],
      zoneOptions: [],
      subtpOptions: [],
      villageOption: [],
      areainmtr: "",
      // villages: {
      //   "1A-1": ["Ambali"],
      //   "1A-2": ["Ambali", "Kadipur", "Bhadiyad"],
      //   "1A-3": ["Ambali"],
      //   "1B": ["Ambali", "Gogla"],
      //   "2A": ["Dholera", "Mondi", "Rahtalav", "Bhimtalav"],
      //   "2B-1": ["Bhadiayd"],
      //   "3A": ["Sandhida", "Dholera", "Mundi", "Otariya"],
      //   "3B": ["Sangasar", "Panchi", "Sandhida"],
      //   "3C": ["Cher", "Sodhi", "Otariya", "Sandhida"],
      //   "4A": ["Dholera", "Mundi"],
      //   "4B-1": ["Dholera", "Mundi", "Sandhida", "Panchi", "Hebatpur"],
      //   "5A": ["Panchi", "Hebatpur"],
      //   "5B": ["Hebatpur", "Bavalyari"],
      //   "5C-1": ["Hebatpur", "Panchi", "Sodhi", "Sangasar"],
      //   "5C-2": ["Bavalyari"],
      //   "6A": ["Hebatpur", "Bavalyari"],
      //   "6B": ["Zankhi", "Banghadh", "Mingalpur"],
      // },
      allFrUser: [],
      allCpUser: [],
      cp: false,
      fr: false,
      allcp: [],
      allfr: [],
      assignToOption: [],
      assignto11: [],
      test: [],
      userId: "",
      fpinyd: "",
      areainyd: "",
      totalowneramount: "",
      totalcpamount: "",
      totalmarketamount: "",

      Juntrirate:0,
      landvideo:"",
      landloacation:'',
      mapimage:'',
      allcpForPayload:0,
      allfrForPayload:0,
      bookingamount:'',

      subTP: {
        1: ["1A-1", "1A-2", "1A-3", "1A-4", "1A-5", "1B"],
        2: ["2A", "2B-1", "2B-2", "2B-3", "2B-4", "2B-5", "2B-6"],
        3: ["3A", "3B", "3C", "3D"],
        4: ["4A", "4B-1", "4B-2"],
        5: ["5A", "5B", "5C-1", "5C-2"],
        6: ["6A", "6B"],
      },

      yardinbigha: "",

      frontrodesize: "",
      cornerplot: "",
      siderodesize1: "",
      siderodesize2: "",
      othersideroadsize: "",
      landusefor: "",
      paymenttime: "",
      tokenamount: "",
      details: "",
      assignto: [],
      subassignto: [],
      landproposal: "",

      flag: false,
      guidline: '',
      highlights: [
        {
          name: "",
        }
      ],
      kmzfile:""
    };
  },
  async mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    const data = JSON.parse(localStorage.getItem("userData"));
    const role = data.role;
    this.userId = data.id;

    if (role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Land") {
          if (this.$route.params.id) {
            if (item.edit !== 1) {
              this.$router.push("/master/crm/land");
            }
          } else if (item.add !== 1) {
            this.$router.push("/master/crm/land");
          }
        }
      });
    }
    this.getState();
    await this.getZone();
    this.getCP();
    this.getFranchise();
    this.ifEdit = !!this.$route.params.id;
    this.ifEdit && this.getEditValue();
  },
  methods: {
    handleSir(){
      this.zone=''
      this.tpno=''
      this.subtpno=''
    },
    async handleChangeFile(e, type, name) {
      this.flag = true;
      const vue=this
      const formData = new FormData();
      formData.append("image", e);
      formData.append("type", type);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${this.accessToken}`,
        },
        data: formData,
        url: `${this.baseApi}/attachment`,
      };
      await axios(requestOptions).then((response) => {
        vue[name] =  process.env.VUE_APP_IMAGE_PATH + response.data.attachment;
        this.flag = false;
      });
    },
    handleAssignto() {
      // let all = [...this.allCpUser, ...this.allFrUser];
      // all.map((item) => {
      //   this.assignto &&
      //     this.assignto.map((inner, index) => {
      //       if (inner == item.id) {
      //         this.assignto[index] = item;
      //       }
      //     });
      // });
    },
    async getFranchise() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getFranchise`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.allFrUser = response.data.data;
          response.data.data.map((item) => {
            if (item.parent == this.userId) {
              this.allfr.push(item);
            }
          });
        })
        .catch((error) => console.log(error, "error"));
    },
    async getCP() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getChannelpartner`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.allCpUser = response.data.data;
          response.data.data.map((item) => {
            if (item.parent == this.userId) {
              this.allcp.push(item);
            }
          });
        })
        .catch((error) => console.log(error, "error"));
    },
    handleCpFr() {
      if (this.cp == false && this.fr == true) {
        this.assignToOption = [];

        this.assignToOption.push({ fullname: "All" });
        this.assignToOption = [...this.assignToOption, ...this.allfr];
      } else if (this.cp == true && this.fr == false) {
        this.assignToOption = [];

        this.assignToOption.push({ fullname: "All" });
        this.assignToOption = [...this.assignToOption, ...this.allcp];
      } else if (this.cp == true && this.fr == true) {
        this.assignToOption = [];

        this.assignToOption.push({ fullname: "All" });
        this.assignToOption = [
          ...this.assignToOption,
          ...this.allfr,
          ...this.allcp,
        ];
      }
      if (this.assignToOption.length == 1) {
        this.assignToOption = [];
      }
    },
    handleAll(e) {
      if (e) {
        if (e[e.length - 1].fullname == "All") {
          this.assignto = this.assignToOption;
          this.assignto.shift();
          this.allcpForPayload = this.cp ? 1 : 0
          this.allfrForPayload = this.fr ? 1 : 0
        } else {
          this.allcpForPayload = 0
          this.allfrForPayload = 0
        }
      }
    },
    handleTotal() {
      this.totalmarketamount =this.roundOfDecimal(
        this.isfp == "Yes"
          ? this.fparea * this.marketrate
          : this.areainyd * this.marketrate)
      this.totalcpamount =this.roundOfDecimal(
        this.isfp == "Yes"
          ? this.fparea * this.cprate
          : this.areainyd * this.cprate)
      this.totalowneramount =this.roundOfDecimal(
        this.isfp == "Yes"
          ? this.fparea * this.ownerrate
          : this.areainyd * this.ownerrate)

      this.totalowneramount = this.roundOfDecimal(this.totalowneramount,2);
    },
    handleTotalAreaMTR(){
      this.areainyd = this.roundOfDecimal(this.areainmtr / 0.836127)
      this.handleTotal();

    },
    handleTotalAreaYard(){
      this.totalarea = this.roundOfDecimal(this.areainyd / this.yardinbigha);
        this.areainmtr = this.roundOfDecimal(this.areainyd * 0.836127);
        this.totalarea =
          isNaN(this.totalarea) || this.totalarea == "Infinity"
            ? 0
            : parseFloat(this.totalarea.toFixed(2));
      this.handleTotal();
    },
    handleTotalArea(e) {
        this.areainyd = this.roundOfDecimal(this.totalarea * this.yardinbigha);
        this.areainmtr = this.roundOfDecimal(this.areainyd * 0.836127);

        this.areainyd = isNaN(this.areainyd)
          ? 0
          : parseFloat(this.areainyd.toFixed(2));
      this.handleTotal();
    },
    handleVillage() {
      this.villagename = "";
      this.villageOption = this.villages[this.subtpno];
    },
    handleSubTP() {
      this.subtpno = "";
      this.subtpOptions = this.subTP[this.tpno];
    },
    async getZone() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/zone`,
      };
      await axios(requestOptions).then((response) => {
        this.zoneOptions = response.data.data.data;
      });
    },
    zonesAtEdit(id) {
      this.zoneOptions.map((item) => {
        if (item.id == id) {
          this.zone = item;
        }
      });
    },
    async handleCity() {
      const reportauthority = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getCityByState`,
        data: {
          State: this.state,
        },
      };
      await axios(reportauthority)
        .then((response) => {
          this.cityOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    async getState() {
      const reportauthority = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getState`,
      };
      await axios(reportauthority)
        .then((response) => {
          this.stateOptions = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    async getEditValue() {
      await axios({
        method: "GET",
        url: `${this.baseApi}/getlandmasterById/${this.$route.params.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          this.getEditItems(json.data.data[0]);
        })
        .catch((error) => console.log(error, "error"));
    },
    getEditItems(data) {
      this.sir = data.zone ? "Yes" : "No";
      this.villagename = data.villagename;
      this.surveynoold = data.surveynoold;
      this.surveynonew = data.surveynonew;
      this.fpno = data.fpno;
      this.khatano = data.khatano;
      this.orignalplotno = data.orignalplotno;
      this.taluka = data.taluka;
      this.patwarihulkano = data.patwarihulkano;
      this.dist = data.dist;
      this.state = data.state;
      this.remarks = data.remarks;
      this.ownerrate = data.ownerrate;
      this.marketrate = data.marketrate;
      this.cprate = data.cprate;
      this.cprate = data.cprate;
      this.marketrate = data.marketrate;
      this.tpno = data.tpno;
      this.subtpno = data.subtpno;

      this.isfp = data.isfp;
      this.fparea = data.fparea;
      this.totalarea = data.totalarea;
      this.withna = data.withna;
      this.nacharges = data.nacharges;

      this.frontrodesize = data.frontrodesize;
      this.cornerplot = data.cornerplot;
      this.siderodesize1 = data.siderodesize1;
      this.siderodesize2 = data.siderodesize2;
      this.othersideroadsize = data.othersideroadsize;
      this.landusefor = data.landusefor;
      this.paymenttime = data.paymenttime;
      this.tokenamount = data.tokenamount;
      this.details = data.details;
      this.assignto = data.assignto;
      this.subassignto = data.subassignto;
      this.landproposal = data.landproposal;
      this.Juntrirate=data.Juntrirate;
      this.landvideo=data.landvideo;
      this.landloacation=data.landloacation;
      this.mapimage=data.mapimage;
      this.allcpForPayload = data.is_cp
      this.allfrForPayload = data.is_franchise
      this.description = data.description
      this.zonesAtEdit(data.zone);
      this.subtpOptions = this.subTP[this.tpno];

      this.yardinbigha = data.yardinbigha;
      this.bookingamount = data.bookingamount;
      this.guidline = data.guidline;
      this.highlights = data.highlights ? JSON.parse(data.highlights) : [];
      this.kmzfile = data.kmzfile
      this.handleTotal();
      this.handleTotalArea("Bigha");
      this.handleAssignto();
    },
    handleDownlineCp(id) {
      this.allCpUser.map((item) => {
        if (item.parent == id && !this.test[item.id]) {
          this.subassignto.push(item.id);
          this.test[item.id] = item.id;
          this.handleDownlineCp(item.id);
        }
      });
    },
    handleDownlineFr(id) {
      this.allFrUser.map((item) => {
        if (item.parent == id) {
          this.subassignto.push(item.id);
          this.test[item.id] = item.id;

          this.handleDownlineFr(item.id);
        }
      });
    },
    handleChildren() {
      this.assignto11.map((item) => {
        this.subassignto.push(item.id);
        if (item.role == "channelpartner") {
          this.handleDownlineCp(item.id);
        } else {
          this.handleDownlineFr(item.id);
        }
      });
    },

    submitForm(e) {
      this.assignto11 = this.assignto !== null ? [...this.assignto] : [];
      this.subassignto = [];
      this.test = {};
      this.handleChildren();
      this.assignto11.map((item, index) => {
        this.assignto11[index] = item.id;
      });
      this.subassignto = this.subassignto.toString();
      const data = {
        villagename: this.villagename,
        surveynoold: this.surveynoold,
        surveynonew: this.surveynonew,
        fpno: this.fpno,
        khatano: this.khatano,
        orignalplotno: this.orignalplotno,
        taluka: this.taluka,
        patwarihulkano: this.patwarihulkano,
        dist: this.dist.City,
        state: this.state.State,
        remarks: this.remarks,
        ownerrate: this.ownerrate,
        cprate: this.cprate,
        marketrate: this.marketrate,
        zone: this.zone?this.zone.id:'',
        tpno: this.tpno,
        bookingamount:this.bookingamount,
        description : this.description,
        isfp: this.isfp,
        fparea: this.fparea,
        totalarea: this.totalarea,
        withna: this.withna,
        nacharges: this.nacharges,
        subtpno: this.subtpno,
        yardinbigha: this.yardinbigha,

        frontrodesize: this.frontrodesize,
        cornerplot: this.cornerplot,
        siderodesize1: this.siderodesize1,
        siderodesize2: this.siderodesize2,
        othersideroadsize: this.othersideroadsize,
        landusefor: this.landusefor,
        paymenttime: this.paymenttime,
        tokenamount: this.tokenamount,
        details: this.details,
        assignto: this.assignto,
        subassignto: this.subassignto,
        landproposal: this.landproposal,
        guidline:this.guidline,
        Juntrirate:this.Juntrirate,
        landvideo:this.landvideo,
        landloacation:this.landloacation,
        mapimage: this.mapimage,
        kmzfile:this.kmzfile,
        highlights: JSON.stringify(this.highlights),


        is_cp : this.allcpForPayload,
        is_franchise : this.allfrForPayload,
      };
      // va: this =,this.$route.params.id ? true : false;
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success == false) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Plese Fill the All Field",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (success) {
          this.flag = true;
          const accessToken = localStorage.getItem("accessToken");
          const baseApi = process.env.VUE_APP_APIENDPOINT;
          await axios({
            method: `${this.ifEdit ? "put" : "post"}`,
            url: this.ifEdit
              ? `${baseApi}/landmaster/${this.$route.params.id}`
              : `${baseApi}/landmaster`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            data: JSON.stringify(data),
          })
            .then((json) => {
              this.flag = false;

              this.$swal({
                title: "Submited",
                text: json.data.message
                  ? `${json.data.message}`
                  : json.data.success
                  ? `${json.data.success}`
                  : "Update SuccessFully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              // console.log(json, "json====");
              this.$router.push("/master/crm/land");
            })
            .catch((error) => {
              this.flag = false;

              this.$swal({
                title: "Error!",
                text: `${error}`,
                icon: "error",
                timer: 5000,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    addHighlightsRow() {
      this.highlights.push({
        name: "",
      });
    },
    removeHighlightsRow(index) {
      this.highlights.splice(index, 1);
    },
    onClickBack() {
      this.$router.go(-1);
    },
  },
};
</script>
